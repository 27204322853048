import React, { useEffect, useState } from 'react';
import axios from 'axios';

function ResultsComponent() {
    const [races, setRaces] = useState([]);
    const [date, setDate] = useState([]);

    useEffect(() => {
        const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001';
        axios.get(`${API_URL}/api/results`)
            .then(response => {
                setRaces(response.data.races);
                setDate(response.data.date);
                console.log(response.data.races[0]?.pronos);
            })
            .catch(error => {
                console.error('There was an error fetching the results:', error);
            });
    }, []);

    return (
        <div>
            <h4>Résultats des Courses</h4>
            <p>Date : {date}</p>
            {races.length > 0 ? (
                races.map((race, index) => (
                    <div key={index}>
                        <h4>{race.name}</h4>
                        <ul>
                            {race.pronos.map((prono, idx) => (
                                <li key={idx}>{prono[2]} {prono[0][0]} {prono[0][1]}: {prono[1]}</li> // prono est un tableau [nom, score]
                            ))}
                        </ul>
                    </div>
                ))
            ) : (
                <p>Aucun résultat à afficher.</p>
            )}
        </div>
    );
}

export default ResultsComponent;
