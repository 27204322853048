import React from 'react';
import ResultsComponent from './components/ResultsComponent';

function App() {
  return (
    <div className="App">
      <h3>Bienvenue sur Horse-AI</h3>
      <ResultsComponent />
    </div>
  );
}

export default App;
